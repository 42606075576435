import cn from 'classnames';
// UI
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Modal from 'ui3/Modal/Modal';

import styles from './SearchBarToggle.module.scss';

type SearchBarToggleProps = {
  searchBarModalIsVisible: boolean;
  toggleSearchBarVisibility: () => void;
  className?: string;
  children?: JSX.Element;
};

const SearchBarToggle: React.FC<SearchBarToggleProps> = ({
  searchBarModalIsVisible,
  toggleSearchBarVisibility,
  children,
  className,
}) => {
  return (
    <>
      <button
        className={cn(styles.searchBarToggleButton, className)}
        onClick={toggleSearchBarVisibility}
        type="button"
        aria-label="Search"
      >
        <MillionsIcon
          name="search"
          className={styles.searchBarToggleButtonIcon}
        />
      </button>

      <Modal
        size="medium"
        className={styles.searchBarToggleModal}
        open={searchBarModalIsVisible}
        onClose={toggleSearchBarVisibility}
        focusTrapped={false}
      >
        {children}
      </Modal>
    </>
  );
};

export default SearchBarToggle;
